import { datadogRum } from '@datadog/browser-rum';

const DATADOG_ENV = process.env.NEXT_PUBLIC_DD_ENV;

const DD_ENABLED_ENVIRONMENTS = ['production', 'staging'];

if (DATADOG_ENV != null && DD_ENABLED_ENVIRONMENTS.includes(DATADOG_ENV)) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID || '',
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN || '',
    site: 'us5.datadoghq.com',
    service: process.env.NEXT_PUBLIC_DD_SERVICE,
    env: process.env.NEXT_PUBLIC_DD_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}
