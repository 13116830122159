/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AppProps } from 'next/app';

import { ReactElement, ReactNode } from 'react';
import '@styles/globalFonts.css';
import '../utils/datadog';
import { NextPage } from 'next';

export type NextPageWithProvider<P = {}, IP = P> = NextPage<P, IP> & {
  provider?: any;
};

export type NextPageWithLayout<P = {}, IP = P> = NextPageWithProvider<P, IP> & {
  getLayout?: (page: ReactElement, pageProps: AppProps & IP) => ReactNode;
};

type AppPropsWithProvider = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithProvider) {
  const getLayout = Component.getLayout ?? ((page) => page);

  if (typeof window !== 'undefined') {
    (window as any).intercomSettings = {
      hide_default_launcher: !pageProps.showIntercomChat ?? true,
    };
  }

  return getLayout(<Component {...pageProps} />, pageProps);
}

export default App;
